<template>
    <v-card id="form_card">
        <v-card-title>
            <v-icon left>mdi-cogs</v-icon>
                <span class="subtitle-1 font-weight-regular application--card--title">Brezplačen čas parkiranja</span>
                <v-spacer></v-spacer>
            <v-btn icon @click="toggleCardContent('card1')">
                <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider v-show="card1"></v-divider>

        
        <v-card-text v-show="card1">
            <!--<p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon>{{ settingsTimeRestrictionText }}</p>-->
            <v-alert
            border="right"
            colored-border
            color="secondary"
            elevation="1"
            icon="mdi-information"
            >
            {{ settingsTimeRestrictionText }}
            </v-alert>
            <v-container grid-list-xl text-x-center align-center>
                <v-form id="form_retention_time" ref="form" v-model="valid" lazy-validation>
                    <v-layout row justify-center v-bind="columnBinding" id="form_card">
                        <v-flex xs12 md4 align-content-center>
                            <v-subheader class="pt-5 pl-0"><strong>Vnesite čas (minute)</strong></v-subheader>
                        </v-flex>
                        <v-flex xs12 md8 align-content-center>
                            <v-text-field
                                v-model="retention_time"
                                label="Vpišite čas v minutah, ki ga imajo na voljo za enkraten prehod zapornic"
                                append-icon="mdi-pencil"
                                clearable
                                type="number"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-card-text>
        <v-divider v-if="card1"></v-divider>
        <v-card-actions v-if="card1">
            <!-- shrani prekliči -->
            <v-btn @click="saveSettings()" color="success" text :disabled="loading" :loading="loading"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                <v-btn @click="cancelSettings()" color="error" :disabled="loading" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: 'retention-time',
    data: () => ({
        valid: true,
        settingsTimeRestrictionText: "V spodnje polje vnesite koliko časa (minut) ima vsako vozilo na voljo, da zapusti parkirišče.",
        card1: true,
        card2: true,
        loading: false,
        retention_time: null, 
        current_value: null,
        dialog:false,
        interval: null,

        retention_time_rules: [v => !!v || 'Polje za vnos časa je obvezno je obvezno'],
    }),

    watch: {

    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        fetchSettingsData() {
            var vm = this
            vm.dialog = false
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('GET_SETTINGS')
            .then(response => {
                //window.console.log(response)
                response.data.forEach(item => {
                    if(Object.prototype.hasOwnProperty.call(item, 'key')) {
                        if(item.key == 'retention_time') {
                            vm.retention_time = item.value
                        }
                    }
                })
            })
            .catch(error => {
                window.console.log("GET_SETTINGS")
                window.console.log(error)
            })
            .finally(()=> {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                }, 700)
            }) 
        },

        saveSettings() {
            var vm = this
            vm.loading = true
            vm.dialog = false
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('SAVE_SETTINGS', {
                retention_time: vm.retention_time
            })
            .then(response => {
                //window.console.log(response)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Nastavitve so bile uspešno posodobljene.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                vm.retention_time = response.retention_time
                vm.fetchSettingsData()
            })
            .catch(error => {
                window.console.log("SAVE_SETTINGS")
                window.console.log(error)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri shranjevanju nastavitev je prišlo do napake. Poskusite ponovno.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
            .finally(()=> {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                    vm.loading = false
                }, 700)
            })
            
        },

        cancelSettings() {
            this.retention_time = null;
            this.$refs.form.resetValidation();
            this.fetchSettingsData()
        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
    },

    mounted() {
        var vm = this
        this.fetchSettingsData();

        vm.interval = setInterval(() =>{
            vm.fetchSettingsData
        }, 30000);
    },

    destroyed() {
        clearInterval(this.interval)
    }
}

</script>

<style scoped>

</style>